<template>
  <div class="footer-spacer"></div>
</template>

<script>
export default {
  name: 'FooterSpacer'
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
.footer-spacer {
  padding-bottom: 170px;

  @include media-breakpoint-up(md) {
    padding-bottom: 100px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 240px;
  }
}
</style>
